import * as icons from './icons';
import * as NewIcons from './new-icons';

export const JoshIcons = {
  alerts: NewIcons.AlertsIcon,
  all_projects: icons.AllProjectsIcon,
  benchmarking: NewIcons.BenchmarkingIcon,
  bulb: icons.BulbIcon,
  bulb_cost: icons.BulbCostIcon,
  bulb_magic: icons.BulbMagicIcon,
  bulb_ray: icons.BulbRayIcon,
  bulb_sustainability: icons.BulbSustainabilityIcon,
  calendar: NewIcons.CalendarIcon,
  chevron: icons.ChevronIcon,
  collapse_sidebar: NewIcons.CollapseSidebarIcon,
  company: icons.CompanyIcon,
  compass: icons.CompassIcon,
  configure2: icons.Configure2Icon,
  configure: icons.ConfigureIcon,
  contact_support2: icons.ContactSupport2Icon,
  contact_support: NewIcons.ContactSupportIcon,
  cost_mode: NewIcons.CostModeIcon,
  dashboard: NewIcons.DashboardIcon,
  dialog_bubble: icons.DialogBubbleIcon,
  dropdown: icons.DropdownIcon,
  edit_profile: NewIcons.EditProfileIcon,
  email: NewIcons.EmailIcon,
  expand_sidebar: NewIcons.ExpandSidebarIcon,
  gear_v1: icons.GearV1Icon,
  gear_v2: icons.GearV2Icon,
  gear_v3: icons.GearV3Icon,
  gears: icons.GearsIcon,
  group_by: icons.GroupBy,
  hard_hat: icons.HardHatIcon,
  hide: icons.HideIcon,
  hide_alt: icons.HideAltIcon,
  historical_items: NewIcons.HistoricalItemsIcon,
  home: icons.HomeIcon,
  ideas: NewIcons.IdeasIcon,
  insights: NewIcons.InsightsIcon,
  insights_classic: icons.InsightsClassicIcon,
  items: NewIcons.ItemsIcon,
  keyboard_shortcuts: NewIcons.KeyboardShortcutsIcon,
  location_pin: icons.LocationPinIcon,
  lock: NewIcons.LockIcon,
  logout: NewIcons.LogoutIcon,
  milestone: NewIcons.MilestoneIcon,
  page: icons.PageIcon,
  program: icons.ProgramIcon,
  project: NewIcons.ProjectIcon,
  project_health: icons.ProjectHealthIcon,
  replay: icons.ReplayIcon,
  reports: NewIcons.ReportsIcon,
  risks: NewIcons.RisksIcon,
  scenarios: NewIcons.ScenariosIcon,
  search: NewIcons.SearchIcon,
  settings: NewIcons.SettingsIcon,
  shareGroupAdd: icons.ShareGroupAddIcon,
  show: icons.ShowIcon,
  show_direct_costs_only: icons.ShowDirectCostsOnlyIcon,
  show_markups_allocated: icons.ShowMarkupsAllocatedIcon,
  show_markups_separated: icons.ShowMarkupsSeparatedIcon,
  size: icons.SizeIcon,
  support_menu: NewIcons.SupportMenuIcon,
  team: NewIcons.TeamIcon,
  timeline: icons.TimelineIcon,
  user: icons.UserIcon,
  visit_help_center: NewIcons.VisitHelpCenterIcon,
  whats_new: NewIcons.WhatsNewIcon,
} as const;

export type JoshIconType = keyof typeof JoshIcons;

export const isJoshIcon = (icon: string): icon is JoshIconType => {
  const joshIconStrings = Object.keys(JoshIcons);
  return joshIconStrings.indexOf(icon as JoshIconType) !== -1;
};

export const getJoshIcon = (icon: string) => {
  return isJoshIcon(icon) ? JoshIcons[icon] : undefined;
};
